@import './variables/grid';
@import './variables/fonts';
@import './variables/colors';
@import './variables/input';
@import './variables/select';
@import './variables/card';
@import './variables/buttons';
@import './variables/shadow';
@import './variables/popup';
@import './variables/chips';
@import './variables/list';
@import './variables/badge';
@import './variables/tooltip';
@import './variables/slider';
@import './variables/spinner';
@import './variables/progress';
@import './variables/icon';
@import './variables/dialog';
@import './variables/scrollbar';
@import './variables/feedback';
@import './variables/table';
@import './variables/ai';
@import './variables/checkbox';
@import './variables/radio';
@import './variables/toggle';
@import './variables/slider';

$influencer-navs-height: 52px;
$top-bar-height: 66px;
$left-bar-width: 60px;
$left-bar-compact-width: 50px;

/**
* List item
*/
$list-item-border-color: $grey-050;
$list-item-hover-color: $grey-050;
$list-item-active-color: $grey-050;
$suggestion-list-item-height: 50px;
$list-item-horizontal-padding: 15px;

/*
* Influencer card
*/
$influencer-card-width: $grid-item-width;
$influencer-card-height: 300px;
$influencer-card-shadow: $al-card-box-shadow;
$influencer-card-shadow-hover: $al-card-box-shadow-hover;
$influencer-card-border-radius: $al-card-border-radius;

/*
* Post
*/
$post-by-row: 3;
$post-gap: $grid-item-gap;
$post-width: $grid-item-width;
$post-height: $post-width;
$post-radius: $al-card-border-radius;

$story-height: 293px;
$story-width: 194px;
$story-gap: 30px;
$story-radius: $al-card-border-radius;

$story-by-row: 5;
$grid-story-width-large: ($story-width + $story-gap) * $story-by-row;
$grid-story-width-medium: ($story-width + $story-gap) * ($story-by-row - 1);
$grid-story-width-small: ($story-width + $story-gap) * ($story-by-row - 2);

$default-line-height: 19px;

$route-padding-top: 70px;
$route-padding-bottom: 70px;

$status-contacted: (
  name: 'state_contacted',
  primary-color: $teal-500,
  bg-color: $teal-050,
  bg-color-hover: $teal-100,
  border-color: $teal-300,
);

$status-discussion: (
  name: 'state_in_discussion',
  primary-color: $indigo-500,
  bg-color: $indigo-050,
  bg-color-hover: $indigo-100,
  border-color: $indigo-300,
);

$status-waiting: (
  name: 'state_waiting',
  primary-color: $orange-500,
  bg-color: $orange-050,
  bg-color-hover: $orange-100,
  border-color: $orange-300,
);

$status-accepted: (
  name: 'state_accepted',
  primary-color: $green-500,
  bg-color: $green-050,
  bg-color-hover: $green-100,
  border-color: $green-300,
);

$status-paid: (
  name: 'state_paid',
  primary-color: $yellow-500,
  bg-color: $yellow-050,
  bg-color-hover: $yellow-100,
  border-color: $yellow-300,
);

$status-rejected: (
  name: 'state_rejected',
  primary-color: $red-400,
  bg-color: $red-050,
  bg-color-hover: $red-100,
  border-color: $red-300,
);

$influencer-status: $status-contacted, $status-discussion, $status-waiting,
  $status-accepted, $status-paid, $status-rejected;

$profile-bg-image: '/lefty-images/bg-avatar.png';

$lefty-form-layout-helper-width: 350px;
$lefty-form-layout-gap: $grid-space-6;

@mixin lefty-variables {
  @include lefty-colors-variables;
  @include lefty-fonts-variables;
  @include lefty-shadow-variables;
  @include lefty-button-variables;
  @include lefty-input-variables;
  @include lefty-badge-variables;
  @include lefty-popup-variables;
  @include scrollbar-css-variables;
  @include lefty-table-variables;
  @include lefty-ai-variables;
  @include lefty-feedback-variables;
  @include lefty-checkbox-variables;
  @include lefty-radio-variables;
  @include lefty-toggle-variables;
  @include lefty-slider-variables;

  --grid-layout-padding: 50px;
  --grid-layout-padding-bottom: 120px;
  --grid-layout-max-width: 1140px;
  --lefty-card-border-radius: 12px;
  --lefty-selectable-card-border-radius: 8px;

  --networks-icons-gap: #{$grid-space-2};
  --networks-icons-minimal-gap: 2px;

  --focus-outline-offset: 1px;
  --focus-outline: solid 2px var(--primary-color-300);
}
