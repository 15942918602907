@use 'sass:math';

@import './variables';
@import './mixins/input';
@import './mixins/fonts';
@import './mixins/layout';
@import './mixins/typo';
@import './mixins/buttons';
@import './mixins/card';
@import './mixins/popup';
@import './mixins/select';
@import './mixins/chips';
@import './mixins/scrollbar';
@import './mixins/ai';
@import './mixins/tabs';
@import './mixins/toggle';
@import './mixins/focus';
@import './mixins/slider';

@mixin box-shadow() {
  // dark is default
  box-shadow: $box-shadow-dark;

  &.medium {
    box-shadow: $box-shadow-medium;
  }

  &.light {
    box-shadow: $box-shadow-light;
  }
}

@mixin chips-content-style {
  margin: 0 8px !important;
}

@mixin ghost-animation {
  background-color: #fff;
  background-image: linear-gradient(
    90deg,
    rgba(var(--ghost-rgb-color), 0.8) 0px,
    rgba(var(--ghost-rgb-color), 0.3) 40px,
    rgba(var(--ghost-rgb-color), 0.8) 80px
  );
  background-size: 100vw;
  animation: ghost-animation 1500ms infinite linear;
  color: transparent !important;
}

@mixin visiblity-transition {
  transition:
    visibility 0.5s linear,
    opacity 0.5s linear;
  -webkit-transition:
    visibility 0.5s linear,
    opacity 0.5s linear;
}

@mixin app-tooltip {
  @include app-paragraph-default;
  color: $black;
  box-sizing: border-box;
  padding: $grid-space-2 $grid-space-3 !important;
  border-radius: 4px;
  max-width: 290px;
  min-width: none;
  /* respect return to line `\n` inside tooltip */
  white-space: pre-line;
  overflow: hidden;
  background-color: white;

  box-shadow:
    0px 3px 10px rgba(0, 0, 0, 0.08),
    0px 0px 1px rgba(0, 0, 0, 0.16);
}

@mixin page-content-border {
  margin-left: 1px;
  box-shadow: 0 0 0 1px var(--content-border-color);
  border-top-left-radius: var(--content-border-radius);
  border-bottom-left-radius: var(--content-border-radius);
}
