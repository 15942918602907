@import '../variables';
@import '../mixins';

h1,
.h1,
.heading-x-large {
  @include app-heading-1();
  margin: 0;
}

h2,
.h2,
.heading-large {
  @include app-heading-2();
  margin: 0;
}

h3,
.h3,
.heading-medium {
  @include app-heading-3();
  margin: 0;
}

h4,
.h4,
.heading-small {
  @include app-heading-4();
  margin: 0;
}

h5,
.h5 {
  @include app-heading-5();
  margin: 0;
}

h6,
.h6 {
  @include app-heading-6();
  margin: 0;
}

.body-1 {
  @include app-body-1();
}

ul,
ol {
  li {
    @include app-paragraph-default;
  }
}

.caption-1 {
  @include app-caption-1();
}

p,
.p {
  @include app-paragraph-default;
  margin: 0;

  &.x-large {
    @include app-paragraph-x-large;
  }

  &.large {
    @include app-paragraph-large;
  }

  &.small {
    @include app-paragraph-small;
  }

  a {
    display: inline-block;
  }
}

a,
.link {
  @include app-link();

  &.black {
    @include app-link(black, $primary-color);
  }
}

b,
strong {
  font-weight: var(--font-weight-regular);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  b,
  strong {
    font-weight: inherit;
  }
}

.no-deco {
  text-decoration: none;
}

.error-message {
  @include error-message();
}

a.back-link {
  transition: all 300ms;
  display: inline-flex;
  align-items: center;
  @include app-body-1;
  color: $black;

  lefty-icon {
    transform: rotate(180deg);
    color: $grey-600;
    margin-right: $grid-space-1;
    margin-bottom: 2px;
  }

  &:hover {
    color: $primary-color;

    lefty-icon {
      color: $primary-color;
    }
  }
}
