@use 'sass:math';

@import '../variables';
@import '../mixins';

p-table {
  .p-datatable-table {
    background-color: var(--content-background-color);
  }

  &:not(.selectable).flex-height {
    .p-datatable-table {
      padding-left: var(--grid-layout-padding);
      padding-right: var(--grid-layout-padding);
    }
  }

  &.selectable {
    &:not(.selection-active) {
      .checkbox-container {
        .checkbox {
          transition: opacity 300ms;
          opacity: 0;
        }
      }
    }

    .checkbox-container {
      width: 20px;

      padding-right: $grid-space-2 !important;
    }

    tr:hover {
      .checkbox-container {
        .checkbox {
          opacity: 1;
        }
      }
    }

    &.selection-active {
      tr:hover {
        cursor: pointer;
      }
    }

    &:not(.flex-height) {
      tbody {
        tr {
          &.selected {
            td {
              background-color: var(--table-row-selected-color);
            }
          }

          &:hover:not(.selected):not(.active) {
            td {
              background-color: var(--table-row-hover-color);
            }
          }
        }
      }
    }

    &.flex-height {
      .checkbox-container {
        border-bottom: none !important;
        padding-left: var(--table-selectable-padding-left) !important;
        background-color: var(--table-background-color) !important;
      }

      th:last-child,
      td:last-child {
        padding-right: var(--table-selectable-padding-right) !important;
      }

      tr::after {
        display: block;
        content: '';
        width: 25px;
      }

      tbody {
        tr {
          &.active {
            .checkbox-container {
              background-color: var(--table-row-active-color);
            }
          }
        }

        tr {
          &.selected {
            td {
              background-color: var(--table-row-selected-color);
            }
          }

          &:hover:not(.selected):not(.active) {
            td {
              background-color: var(--table-row-hover-color);
            }
          }
        }
      }
    }
  }
}

.p-datatable-table {
  thead {
    background-color: var(--table-background-color);

    th {
      background-color: var(--table-background-color);
      padding-top: var(--table-header-cell-padding-top);
      padding-bottom: var(--table-header-cell-padding-bottom);
      border-bottom: solid 1px var(--table-header-border-color);
      box-sizing: border-box;
      text-align: left;
      height: var(--table-header-height);

      &:first-child {
        padding-left: var(--table-padding-left);
      }

      &:not(:first-child) {
        padding-left: var(--table-cell-padding-left);
      }

      &:last-child {
        padding-right: var(--table-padding-right);
        text-align: end;
      }

      &:not(:last-child) {
        padding-right: var(--table-cell-padding-right);
      }
    }
  }

  tbody,
  tfoot {
    td {
      padding-top: var(--table-cell-padding-top);
      padding-bottom: var(--table-cell-padding-bottom);
      box-sizing: border-box;

      &:first-child {
        padding-left: var(--table-padding-left);
      }

      &[cell-index='0'] {
        font-size: var(--heading-4-font-size);
        font-weight: var(--heading-4-font-weight);
        line-height: var(--heading-4-line-height);
      }

      &:not([cell-index='0']) {
        font-size: var(--body-1-font-size);
        font-weight: var(--body-1-font-weight);
        line-height: var(--body-1-line-height);

        padding-left: var(--table-cell-padding-left);
      }

      &:last-child {
        padding-right: var(--table-padding-right);
        text-align: end;
      }

      &:not(:last-child) {
        padding-right: var(--table-cell-padding-right);
      }

      .cell-label {
        display: block;
        width: 100%;
        white-space: nowrap;
      }
    }

    tr {
      &.ghost {
        td {
          .cell-label {
            @include ghost-animation;
            height: 14px;
            border-radius: 4px;
          }
        }
      }
    }
  }

  tbody {
    td {
      color: var(--table-row-font-color);
      background-color: var(
        --table-row-background-color,
        var(--table-background-color)
      );
    }

    tr {
      &:not(:last-child) {
        td {
          border-bottom: solid 1px var(--table-cell-border-color);
        }
      }
    }
  }

  tfoot {
    td {
      color: var(--table-footer-font-color);
      border-top: solid 1px var(--table-footer-border-color);
      background-color: var(--table-footer-background-color);
    }
  }
}
